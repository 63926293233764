
import { mapMutations } from 'vuex'
import { auth, analytics } from '~/plugins/firebase.js'
import { logEvent } from 'firebase/analytics'
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
} from 'firebase/auth'

export default {
  head() {
    return {
      title: 'Login or Sign Up',
    }
  },
  data: () => ({
    email: null,
    loading: false,
    method: null,
  }),
  mounted() {
    if (this.$route.query.oobCode) {
      this.completeSigninWithLink()
      this.loading = true
      this.method = 'email'
    }
  },
  methods: {
    ...mapMutations(['SHOW_MESSAGE']),
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    completeSigninWithLink() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
          email = window.prompt('Please provide your email for confirmation')
        }
        this.email = email
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            window.location.assign('/graduates/')
            this.loading = false
            result?.additionalUserInfo?.isNewUser
              ? logEvent(analytics, 'sign_up')
              : logEvent(analytics, 'sign_in')
          })
          .catch((e) => {
            this.SHOW_MESSAGE({
              content: e.message,
              color: 'error',
            })
            this.loading = false
          })
      }
    },
    signInWithLink() {
      if (!this.email) return
      this.loading = true
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      }
      sendSignInLinkToEmail(auth, this.email, actionCodeSettings)
        .then(() => {
          this.SHOW_MESSAGE({
            content: `Click the link we sent to ${this.email} to sign in.`,
            color: 'info',
          })
          window.localStorage.setItem('emailForSignIn', this.email)
          this.loading = false
          this.method = null
        })
        .catch((error) => {
          this.SHOW_MESSAGE({
            content: error.message,
            color: 'error',
          })
          this.loading = false
        })
    },
    signInWithGoogle() {
      const provider = new GoogleAuthProvider()
      this.loading = true
      signInWithPopup(auth, provider)
        .then((result) => {
          const { isNewUser } = getAdditionalUserInfo(result)
          if (isNewUser) {
            logEvent(analytics, 'sign_up')
          } else {
            logEvent(analytics, 'sign_in')
          }
          this.$router.push({
            path: '/graduates/',
          })
        })
        .catch((error) => {
          this.SHOW_MESSAGE({ content: error.message, color: 'error' })
          this.loading = false
        })
    },
  },
}
